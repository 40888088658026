<template>
  <main class="ui world quote-page quote-pdf">
    <div v-if="quote" class="calculator-wrapper">
      <div class="cols logo">
        <img v-if="quote.dealer.logo_url" :src="quote.dealer.logo_url" alt="Logo">
        <img v-else alt="Logo" src="@/assets/img/logo-full.svg">

        <div class="info company-info">
          <h3>{{ $t('Dealer gegevens') }}</h3>
          <p>{{ quote.dealer.name }}</p>
          <p>{{ quote.dealer.address.address1 }}</p>
          <p>{{ quote.dealer.address.postal_code }} {{ quote.dealer.address.locality }}</p>
          <p v-if="quote.dealer.phone">T: {{ quote.dealer.phone }}</p>
          <p v-if="quote.dealer.email">M: {{ quote.dealer.email }}</p>
          <p v-if="quote.dealer.website">W: {{ quote.dealer.website }}</p>
          <p v-if="quote.dealer.vat_number">Btw: {{ quote.dealer.vat_number }}</p>
          <div>{{ $t('Opgemaakt door:') }} {{ quote.created_by.profile.first_name }} {{ quote.created_by.profile.last_name }}</div>
          <div>{{ quote.created_by.email }}</div>
        </div>
      </div>

      <div class="cols">
        <div class="info personal-info">
          <h3>{{ $t('Jouw gegevens') }}</h3>
          <div>{{ quote.address.first_name }} {{ quote.address.last_name }}</div>
          <div v-if="quote.address.email ">E: {{ quote.address.email }}</div>
          <div v-if="quote.address.phone">T: {{ quote.phone }}</div>
          <div v-if="quote.address.address1">{{ quote.address1 }}</div>
          <div>{{ quote.address.postal_code }} {{ quote.address.locality }}</div>
          <div>{{ countries[quote.address.country_code] }}</div>
          <div v-if="quote.address.company_name">{{ quote.address.company_name }}</div>
          <div v-if="quote.vat_number">{{ quote.vat_number }}</div>
        </div>

        <div class="info invoice-info">
          <h3>{{ $t('Offerte gegevens') }}</h3>
          <div>Datum: {{ datetime.fromISO(quote.created).toFormat('dd-MM-y') }}</div>
          <div>Geldig tot: {{ datetime.fromISO(quote.expires_on).toFormat('dd-MM-y') }}</div>
        </div>
      </div>

      <div class="title">
        <div>{{ $t('Offerte') }}</div>
        <div class="number">{{ quote.number }}</div>
      </div>

      <div class="step-contents">
        <div class="overview-details">
          <div v-for="articulatingScreen in quote.articulating_screens" :key="'as-' + articulatingScreen.id" class="calculator-wrapper">
            <div class="calculator">
              <div class="flex">
                <h3>{{ $t('Zonnescherm:') }} {{ articulatingScreen.name }}</h3>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Type') }}</div>
                <div class="value">{{ articulatingScreen.type }}</div>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Breedte en uitval') }}</div>
                <div class="value">{{ articulatingScreen.width }}mm (+ 950mm) x {{ articulatingScreen.reach }}mm</div>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Motor') }}</div>
                <div class="value">{{ articulatingScreen.engine }}</div>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Bediening') }}</div>
                <div class="value">{{ $t(articulatingScreen.connection) }}</div>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Muursteunen') }}</div>
                <div class="value">{{ $t('Inclusief') }} {{ articulatingScreen.wall_mounts_amount }} {{ $t('muursteunen') }}</div>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Kleur kast') }}</div>
                <div class="value">RAL{{ articulatingScreen.ral }}</div>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Kleur doek') }}</div>
                <div class="value">{{ articulatingScreen.fabric_color }}</div>
              </div>
              <div class="detail subtotal">
                <div class="label">{{ $t('Configuratie prijs') }}</div>
                <div class="value">{{ $n(articulatingScreen.base_price, 'currency', 'nl-BE') }}</div>
              </div>
              <div class="detail options">
                <div class="label">{{ $t('Opties') }}</div>
                <template v-if="articulatingScreen.options && articulatingScreen.options.length > 0">
                  <div v-for="option in articulatingScreen.options" :key="'as-option-' + option.id" class="value">
                    <div class="title">{{ option.amount }}x {{ $t(option.name) }}</div>
                    <span>{{ $n(option.calculated_total, 'currency', 'nl-BE') }}</span>
                  </div>
                </template>
                <div v-else class="value">
                  {{ $t('Geen opties') }}
                </div>
              </div>
              <div class="detail subtotal">
                <div class="label">{{ $t('Totaalprijs') }}</div>
                <div class="value">{{ $n(articulatingScreen.calculated_total_price, 'currency', 'nl-BE') }}</div>
              </div>
            </div>
          </div>

          <div v-for="shutter in quote.shutters" :key="'shutter-' + shutter.id" class="calculator-wrapper">
            <div class="calculator">
              <div class="flex">
                <h3>{{ $t('Rolluik:') }} {{ shutter.name }}</h3>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Type') }}</div>
                <div class="value">{{ $t(shutter.type) }}</div>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Breedte en hoogte') }}</div>
                <div v-if="shutter.measurement_style === 'cabinet_rails'" class="value">{{ shutter.width }}mm (+ {{ shutter.added_width }}) x {{ shutter.height }}mm (+ {{ shutter.added_height }}mm)</div>
                <div v-else-if="shutter.measurement_style === 'cabinet'" class="value">{{ shutter.width }}mm x {{ shutter.height }}mm (+ {{ shutter.added_height }}mm)</div>
                <div v-else class="value">{{ shutter.width }}mm x {{ shutter.height }}mm</div>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Motor') }}</div>
                <div class="value">{{ $t(shutter.engine) }}</div>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Bediening') }}</div>
                <div class="value">{{ $t(shutter.connection) }}</div>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Kleur kast') }}</div>
                <div v-if="getShutterCabinetColor(shutter)" class="value">{{ getShutterCabinetColor(shutter).ral ? 'RAL ' + getShutterCabinetColor(shutter).ral : $t('Alternatieve RAL-kleur') }}</div>
                <div v-else class="value">/</div>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Kleur lamellen') }}</div>
                <div v-if="getShutterSlatsColor(shutter)" class="value">{{ 'RAL ' + getShutterSlatsColor(shutter).ral + ' - ' + getShutterSlatsColor(shutter).name }}</div>
                <div v-else class="value">/</div>
              </div>
              <div class="detail subtotal">
                <div class="label">{{ $t('Configuratie prijs') }}</div>
                <div class="value">{{ $n(shutter.base_price, 'currency', 'nl-BE') }}</div>
              </div>
              <div v-if="shutter.shortened_engine" class="detail options">
                <div class="label">{{ $t('Verkorte motor') }}</div>
                <div class="value">
                  <div class="title">{{ $t('Meerprijs') }}</div>
                  <span>{{ $n(shutter.additional_price_shortened_engine, 'currency', 'nl-BE') }}</span>
                </div>
              </div>
              <div v-if="getShutterCabinetColor(shutter) && getShutterCabinetColor(shutter).cabinet_total" class="detail options">
                <div class="label">{{ $t('Meerprijs kleur kast') }}</div>
                <div class="value">
                  <div class="title">{{ $t('Kast') }}</div>
                  {{ $n(getShutterCabinetColor(shutter).cabinet_total, 'currency', 'nl-BE') }}
                </div>
                <div class="value">
                  <div class="title">{{ $t('Geleiders') }}</div>
                  {{ $n(getShutterCabinetColor(shutter).side_rails_total, 'currency', 'nl-BE') }}
                </div>
                <div class="value">
                  <div class="title">{{ $t('Onderlat') }}</div>
                  {{ $n(getShutterCabinetColor(shutter).bottom_bar_total, 'currency', 'nl-BE') }}
                </div>
              </div>
              <div v-if="getShutterSlatsColor(shutter) && getShutterSlatsColor(shutter).slats_total" class="detail options">
                <div class="label">{{ $t('Meerprijs kleur lamellen') }}</div>
                <div class="value">
                  <div class="title">{{ getShutterSlatsColor(shutter).name }}</div>
                  {{ $n(getShutterSlatsColor(shutter).slats_total, 'currency', 'nl-BE') }}
                </div>
              </div>
              <div class="detail options">
                <div class="label">{{ $t('Opties') }}</div>
                <template v-if="shutter.options && shutter.options.length > 0">
                  <div v-for="option in shutter.options" :key="'shutter-option-' + option.id" class="value">
                    <div class="title">{{ option.amount }}x {{ $t(option.name) }}</div>
                    <span>{{ $n(option.calculated_total, 'currency', 'nl-BE') }}</span>
                  </div>
                </template>
                <div v-else class="value">
                  {{ $t('Geen extra\'s') }}
                </div>
              </div>
              <div class="detail subtotal">
                <div class="label">{{ $t('Totaalprijs') }}</div>
                <div class="value">{{ $n(shutter.calculated_total_price, 'currency', 'nl-BE') }}</div>
              </div>
            </div>
          </div>

          <div v-for="screen in quote.screens" :key="'screen-' + screen.id" class="calculator-wrapper">
            <div class="calculator">
              <div class="flex">
                <h3>{{ $t('Screen:') }} {{ screen.name }}</h3>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Type') }}</div>
                <div class="value">{{ $t(screen.type) }}</div>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Breedte en hoogte') }}</div>
                <div class="value" v-if="screen.added_width && screen.added_height">{{ screen.width }}mm (+ {{ screen.added_width }}mm) x {{ screen.height }}mm (+ {{ screen.added_height }}mm)</div>
                <div class="value">{{ screen.width }}mm x {{ screen.height }}mm</div>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Motor') }}</div>
                <div class="value">{{ $t(screen.engine) }}</div>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Bediening') }}</div>
                <div class="value">{{ $t(screen.connection) }}</div>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Doeksoort') }}</div>
                <div class="value">{{ $t(screen.fabric_type) }}</div>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Kleur kast') }}</div>
                <div v-if="getScreenCabinetColor(screen)" class="value">{{ getScreenCabinetColor(screen).ral ? 'RAL ' + getScreenCabinetColor(screen).ral : $t('Alternatieve RAL-kleur') }}</div>
                <div v-else class="value">/</div>
              </div>
              <div class="detail">
                <div class="label">{{ $t('Kleur lamellen') }}</div>
                <div v-if="getScreenFabricColor(screen)" class="value">{{ getScreenFabricColor(screen).name }}</div>
                <div v-else class="value">/</div>
              </div>
              <div class="detail subtotal">
                <div class="label">{{ $t('Configuratie prijs') }}</div>
                <div class="value">{{ $n(screen.base_price, 'currency', 'nl-BE') }}</div>
              </div>
              <div v-if="screen.shortened_engine" class="detail options">
                <div class="label">{{ $t('Verkorte motor') }}</div>
                <div class="value">
                  <div class="title">{{ $t('Meerprijs') }}</div>
                  <span>{{ $n(screen.additional_price_shortened_engine, 'currency', 'nl-BE') }}</span>
                </div>
              </div>
              <div v-if="screen.fabric_type_addition_price" class="detail options">
                <div class="label">{{ $t('Meerprijs doeksoort') }}</div>
                <div class="value">
                  <div class="title">{{ $t(screen.fabric_type) }}</div>
                  {{ $n(screen.fabric_type_addition_price, 'currency', 'nl-BE') }}
                </div>
              </div>
              <div class="detail options">
                <div class="label">{{ $t('Opties') }}</div>
                <template v-if="screen.options && screen.options.length > 0">
                  <div v-for="option in screen.options" :key="'shutter-option-' + option.id" class="value">
                    <div class="title">{{ option.amount }}x {{ $t(option.name) }}</div>
                    <span>{{ $n(option.calculated_total, 'currency', 'nl-BE') }}</span>
                  </div>
                </template>
                <div v-else class="value">
                  {{ $t('Geen extra\'s') }}
                </div>
              </div>
              <div class="detail subtotal">
                <div class="label">{{ $t('Totaalprijs') }}</div>
                <div class="value">{{ $n(screen.calculated_total_price, 'currency', 'nl-BE') }}</div>
              </div>
            </div>
          </div>

          <div class="calculator-wrapper">
            <div class="calculator">
              <div class="flex">
                <h3>{{ $t('Extra\'s') }}</h3>
              </div>

              <div class="detail extras">
                <template v-if="quote.extras && quote.extras.length > 0">
                  <div v-for="extra in quote.extras" :key="'quote-extra-' + extra.id" class="value">
                    <div>
                      <div class="title">{{ extra.title }}</div>
                      <div class="description" v-html="extra.description"></div>
                    </div>
                    <template v-if="extra.show_price">
                      <span v-if="extra.price">{{ $n(extra.price, 'currency', 'nl-BE') }}</span>
                      <span v-else>{{ extra.percentage }}%</span>
                    </template>
                    <span v-else>-</span>
                  </div>
                </template>
                <div v-else class="value">
                  {{ $t('Geen extra\'s') }}
                </div>
              </div>
            </div>
          </div>

          <div class="calculator-wrapper">
            <div class="calculator">
              <div class="flex">
                <h3>{{ $t('Plaatsingskosten') }}</h3>
              </div>

              <div class="detail extras">
                <div class="value" v-if="quote.shutters.length && quote.installation_cost_shutters">
                  <div>
                    <div class="title">{{ $t('Plaatsingskost rolluiken') }}</div>
                    <div class="description">{{ $t('Aantal:') }} {{ quote.shutters.length }}</div>
                  </div>
                  <span>{{ $n(quote.installation_cost_shutters, 'currency', 'nl-BE') }}</span>
                </div>

                <div class="value" v-if="quote.articulating_screens.length && quote.installation_cost_articulating_screens">
                  <div>
                    <div class="title">{{ $t('Plaatsingskost zonneschermen') }}</div>
                    <div class="description">{{ $t('Aantal:') }} {{ quote.articulating_screens.length }}</div>
                  </div>
                  <span>{{ $n(quote.installation_cost_articulating_screens, 'currency', 'nl-BE') }}</span>
                </div>

                <div class="value" v-if="quote.screens.length && quote.installation_cost_screens">
                  <div>
                    <div class="title">{{ $t('Plaatsingskost screens') }}</div>
                    <div class="description">{{ $t('Aantal:') }} {{ quote.screens.length }}</div>
                  </div>
                  <span>{{ $n(quote.installation_cost_screens, 'currency', 'nl-BE') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="overview-footer">
          <div class="border"></div>

          <div class="price">
            <div class="label">{{ $t('Totale prijs (excl. btw)') }}</div>
            <div class="value">
              <div :class="quote.overwritten_total ? 'old' : ''">
                {{ $n(quote.calculated_total_without_vat, 'currency', 'nl-BE') }}
              </div>
              <div v-if="!quote.vat_rate" class="info">{{ $t('Btw verlegd') }}</div>
            </div>
          </div>
          <div v-if="quote.vat_rate > 0" class="price">
            <div class="label">{{ $t('Btw') }} {{ quote.vat_rate }}%</div>
            <div class="value">{{ $n(quote.calculated_total_vat, 'currency', 'nl-BE') }}</div>
          </div>
          <div v-if="quote.vat_rate > 0" class="price">
            <div class="label">{{ $t('Totale prijs (incl. btw)') }}</div>
            <div class="value">{{ $n(quote.calculated_total_with_vat, 'currency', 'nl-BE') }}</div>
          </div>
          <div v-if="quote.overwritten_total" class="price">
            <div class="label">{{ $t('Uw prijs') }}</div>
            <div class="value">{{ $n(quote.overwritten_total, 'currency', 'nl-BE') }}</div>
          </div>
          <div class="price-info">{{ $t('Deze offerte kan gewijzigd worden na definitieve opmeting.') }}</div>
        </div>
      </div>

      <div v-if="quote.signature_client || quote.signature_dealer" class="signatures">
        <div v-if="quote.signature_dealer" class="signature">
          <div>
            {{ $t('Handtekening dealer') }}
          </div>
          <div class="signature-image">
            <img :src="quote.signature_dealer.file.url" alt="Signature customer">
          </div>
        </div>

        <div v-if="quote.signature_client" class="signature">
          <div>
            {{ $t('Handtekening klant:') + ' ' + quote.address.first_name + ' ' + quote.address.last_name }}
          </div>
          <div class="signature-image">
            <img :src="quote.signature_client.file.url" alt="Signature customer">
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import 'vue-select/dist/vue-select.css'
import { DateTime } from 'luxon'
import Calculators from '@/calculators'

export default {
  name: 'CalculatorPdf',
  components: {},
  layout: '',
  mixins: [
    Calculators
  ],
  data () {
    return {
      quote: null,
      connections: { wired: this.$t('wired'), wireless: this.$t('wireless') },
      engineOptions: [],
      options: [],
      datetime: DateTime
    }
  },
  async mounted () {
    document.querySelector('body').classList.add('page-quote-pdf')

    if (this.$route.params.id) {
      const hash = this.$route.params.hash
      await ApiService.fetchQuote(this.$route.params.id, hash).then(async res => {
        this.quote = res.data
        this.quote.vat_rate = parseInt(this.quote.vat_rate)
      })
    }
  },
  methods: {
    getScreenCabinetColor (screen) {
      return screen.colors.find(color => color.component === 'cabinet')
    },
    getScreenFabricColor (screen) {
      return screen.colors.find(color => color.component === 'fabric')
    },
    getShutterCabinetColor (shutter) {
      return shutter.colors.find(color => color.component === 'cabinet_square' || color.component === 'cabinet_round')
    },
    getShutterSlatsColor (shutter) {
      return shutter.colors.find(color => color.component === 'slats')
    }
  }
}
</script>
